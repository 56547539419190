/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hi,",
  title2: "TojuSunmola",
  logo_name: "TojuSunmola",
  nickname: "TJ",
  full_name: "Toju sunmola",
  subTitle: "I am  designer who uses fabric, nails and digital interface as her medium of expression.",
  resumeLink:
    "https://drive.google.com/drive/folders/119CHxISbFOUoHLjaOobq5em8LAiTpziI?usp=sharing",
  mail: "mailto:tojusunmola@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  behance: "https://www.behance.net/tojusunmola",
  dribbble: "https://dribbble.com/Tojusunmola/",
  linkedin: "https://www.linkedin.com/in/toju-sunmola-35a5b4193//",
  gmail: "tojusunmola@gmail.com",
  instagram: "https://www.instagram.com/custommadebytoju",
};

const skills = {
  data: [
    {
      title: "UI/UX Designs",
      fileName: "FullStackImg",
      skills: [
        "⚡ Create overall concepts for the user experience within a business webpage or product, ensuring all interactions are intuitive and easy for customers.",
        "⚡ Illustrate design ideas using storyboards, process flows and sitemaps.",
        "⚡ Design graphic user interface elements, like menus, tabs and widgets.",
        "⚡ Build page navigation buttons and search fields.",
        "⚡ Develop UI mockups and prototypes that clearly illustrate how sites function and look like.",
        "⚡ Improve the look and feel of interactive computers and product software.",
      ],
      softwareSkills: [
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#5b77ef",
          },
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
      ],
    },
    {
      title: "About Me",
      fileName: "CloudInfraImg",
      skills: [
        `Hello Everyone!`,

        `My name is Toju Sunmola and I am a Fashion Designer, Nail Artist, and a Digital Designer.`,

        ` As an artist, I use nails, fabric, and web user interface as my medium of expression. I am an expert in creating visuals and interactions that are easy to understand and are appealing to the client's eyes.`,

        `My success is driven by my ability to be creative, and innovative; My willingness to learn and explore has helped me grow and be better in various fields.`,
      ],
      softwareSkills: [
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "University of Houston-Victoria",
      subtitle: "Bachelor of Applied Science",
      logo_path: "ssec.png",
      alt_name: "SSEC",
      duration: "2021 - 2024",
      descriptions: [
        "⚡ I'm currently pursuing my bachelors in Psychology.",
      ],
      website_link: "https://www.uhv.edu/",
    },
    {
      title: "Ladoke Akintola University of Technology",
      subtitle: "Bachelor of Applied Science",
      logo_path: "ssgandhy.png",
      alt_name: "SSGC",
      duration: "2011 - 2015",
      descriptions: [
        "⚡ I studied Pure and Applied Biology.",
      ],
      website_link: "https://www.lautech.edu.ng/",
    },
  ],
};

const certifications = {
  certifications: [
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Fashion",
  description:
    "Toju's Fashion is a renowned brand led by Toju Sunmola, an experienced designer since 2014. We offer fashion courses for all skill levels, emphasizing diversity and community engagement. Master garment construction or develop intricate designs with Toju's Fashion.",
  header_image_path: "toju_fashion.png",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "UI/UX Designer (Freelance)",
          company: "Farrock Staffing Agency",
          company_url: "",
          logo_path: "valora.jpg",
          duration: "Aug 2021 - present",
          location: "Texas, United State",
          description: `Create, improve and use wireframes, prototypes, style guides, user flows.
          Effectively communicate your interaction ideas using any of these methods.Create, improve and use wireframes, prototypes, style guides, user flows. Effectively communicate your interaction ideas using any of these methods.
          Skills: Design · Figma (Software) · User Interface Design · User Experience (UX)
          `,
          color: "#0071C5",
        },
        {
          title: "User Interface Designer (Full Time Remote)",
          company: "Abod Brand",
          company_url: "",
          logo_path: "nightowls.jpg",
          duration: "jun 2021 - jan 2022",
          location: "Remote",
          description: `•Create overall concepts for the user experience within a business webpage or product, ensuring all interactions are intuitive and easy for customers

          •Illustrate design ideas using storyboards, process flows and sitemaps.
          
          •Design graphic user interface elements, like menus, tabs and widgets
          
          •Build page navigation buttons and search fields.
          
          •Develop UI mockups and prototypes that clearly illustrate how sites function and look like
          
          •Improve the look and feel of interactive computers and product software•Create overall concepts for the user experience within a business webpage or product, ensuring all interactions are intuitive and easy for customers •Illustrate design ideas using storyboards, process flows and sitemaps. •Design graphic user interface elements, like menus, tabs and widgets •Build page navigation buttons and search fields. •Develop UI mockups and prototypes that clearly illustrate how sites function and look like •Improve the look and feel of interactive computers and product software
          Skills: Design · Figma (Software) · User Experience (UX)
          `,
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Other Work Experience",
      experiences: [
        {
          title: "Nail Artist(Self-employed)",
          company: "Toju's Nails",
          company_url: "https://tojusnails.com/",
          logo_path: "cwoc.png",
          duration: "Jul 2019 - Present",
          location: "Texas, United State",
          description:
            `•Assessing conditional aspects of client's hands and advised proper treatment
            Advanced technical skills within the following services: manicures, pedicures, nail art and designs.
            
            •Advised clients with information on services and/or products to accommodate their needs•Assessing conditional aspects of client's hands and advised proper treatment Advanced technical skills within the following services: manicures, pedicures, nail art and designs. •Advised clients with information on services and/or products to accommodate their needs
            Skills: Design · art`,
          color: "#4285F4",
        },
        {
          title: "Fashion Designer(Self-employed)",
          company: "Toju's Fashion",
          company_url: "https://tojuss.com/",
          logo_path: "skillenza.png",
          duration: "Mar 2013 - Present",
          location: "Texas, United State",
          description:
           `•Managing design process from conception through to final styling.

           •Selecting fabrics and trims.
           
           •Creating clothing patterns for custom made orders•Managing design process from conception through to final styling. •Selecting fabrics and trims. •Creating clothing patterns for custom made orders
           Skills: Design · Fashion Design`,
          color: "#196acf",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My best experience is to create, improve and use wireframes. Below are some of my projects. Note that not all of my projects are here, you can visit my behance profile for more.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "Online Furniture Store Web Page",
      url: "https://www.behance.net/gallery/147834477/Online-Furniture-Store-Web-Page",
      img: process.env.PUBLIC_URL + '/furniture.png',
     
      languages: [
        {
          name: "figma",
        },
      ],
    },
    {
      id: "1",
      name: "Banking App - Remix",
      url: "https://www.behance.net/gallery/147661107/Banking-app-Remix",
      img: process.env.PUBLIC_URL + '/banking.png',
      languages: [
        {
          name: "figma",
        },
      ],
    },
    {
      id: "2",
      name: "Dating registration- Ife pade.",
      url: "https://www.behance.net/gallery/147285511/Dating-registration-Ife-pade",
      img: process.env.PUBLIC_URL + '/dating.png',
      languages: [
        {
          name: "figma",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
