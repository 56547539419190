import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Educations from "../../containers/education/Educations";
import Certifications from "../../containers/certifications/Certifications";
import EducationImg from "./EducationImg";
import "./EducationComponent.css";
import { Fade } from "react-reveal";

function Education(props) {
  const theme = props.theme;
  return (
    <div className="education-main">
      <Header theme={props.theme} setTheme={props.setTheme} />
      <div className="basic-education">
        <Fade bottom duration={2000} distance="40px">
          <div className="heading-div">
            <div className="heading-img-div">
            {
                theme.name === 'light' ? 
                <img src={process.env.PUBLIC_URL + '/nails.png'} alt='femaledev' /> :
                <img src={process.env.PUBLIC_URL + '/tj_nails.png'} alt='femaledev' />
              }
            
            </div>
            <div className="heading-text-div">
              <h1 className="heading-text" style={{ color: theme.text }}>
                Nails
              </h1>
              {/* <h3 className="heading-sub-text" style={{ color: theme.text }}>
                Basic Qualification
              </h3> */}
              <p
                className="experience-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                {" "}
                Toju Nails is a beauty salon that offers high-quality services
                such as Gel/Shellac, Gel X, Acrylics, Spar Pedicures and Medical Pedicures. Our skilled
                professionals prioritize hygiene standards and customer
                satisfaction, ensuring that each appointment is personalized to
                meet your preferences. Step into our salon and experience our
                dedication to providing superior beauty services.
              </p>
            </div>
          </div>
        </Fade>
        <Educations theme={props.theme} />
      </div>
      <Footer theme={props.theme} />
    </div>
  );
}

export default Education;
