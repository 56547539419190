import React from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { skills } from "../../portfolio";
import { Fade } from "react-reveal";
import FullStackImg from "./FullStackImg";
import CloudInfraImg from "./CloudInfraImg";
import { Link } from "react-router-dom";
function GetSkillSvg(props) {
  if (props.fileName === "FullStackImg")
    return (
      <div className="home-page">
        <div className="image-links">
          <div className="image-link">
            <h2>Fashion</h2>
            <Link to="/fashion">
              <img
                src={process.env.PUBLIC_URL + "/img1.jpg"}
                alt="Fashion"
                className="responsive-image"
              />
            </Link>
          </div>
          <div className="image-link">
            <h2>Nails</h2>
            <Link to="/nails">
              <img
                src={process.env.PUBLIC_URL + "/img2.jpg"}
                alt="Nails"
                className="responsive-image"
              />
            </Link>
          </div>
          <div className="image-link">
            <h2>Digital Designs</h2>
            <Link to="/digital-designs">
              <img
                src={process.env.PUBLIC_URL + "/img3.jpg"}
                alt="Digital Designs"
                className="responsive-image"
              />
            </Link>
          </div>
        </div>
      </div>
    );
  else if (props.fileName === "CloudInfraImg")
    return <CloudInfraImg theme={props.theme} />;
  // return <DesignImg theme={props.theme} />;
}

function SkillSection(props) {
  const theme = props.theme;
  return (
    <div>
      {skills.data.map((skill, index) => {
        if (index % 2 === 0) {
          return (
            <div className="skills-main-div">
              <Fade left duration={2000}>
                <div className="skills-image-div">
                  <GetSkillSvg fileName={skill.fileName} theme={theme} />
                </div>
              </Fade>
            </div>
          );
        } else {
          return (
            <div className="skills-main-div">
              <div className="skills-text-div">
                <Fade left duration={1000}>
                  <h1 className="skills-heading" style={{ color: theme.text }}>
                    {skill.title}
                  </h1>
                </Fade>
                <Fade left duration={1500}>
                  <SoftwareSkill logos={skill.softwareSkills} />
                </Fade>
                <Fade left duration={2000}>
                  <div>
                    {skill.skills.map((skillSentence) => {
                      return (
                        <p
                          className="subTitle skills-text"
                          style={{ color: theme.secondaryText }}
                        >
                          {skillSentence}
                        </p>
                      );
                    })}
                  </div>
                </Fade>
              </div>
              <Fade right duration={2000}>
                <div className="skills-image-div">
                  <img src={process.env.PUBLIC_URL + "/tj_big.png"} alt="tj" />
                </div>
              </Fade>
            </div>
          );
        }
      })}
    </div>
  );
}

export default SkillSection;
