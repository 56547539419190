import React from "react";
import "./Educations.css";
import { style } from "glamor";
function Educations(props) {
  const theme = props.theme;
  const styles = style({
    backgroundColor: `${theme.accentBright}`,
    display: "block",
    margin: "10px auto", // Center the links horizontally
    textDecoration: "none",
    color: theme.text,
    textAlign: "center",
    paddingRight: "20px", // Add right padding for spacing
    ":hover": {
      boxShadow: `0 5px 15px ${theme.accentBright}`,
    },
  });
  const linkStyles = {
  
  };
  return (
    <div id="educations">
      <div className="educations-header-div experience-accord">
        <h1 className="educations-header" style={{ color: theme.text }}>
          Gallery
        </h1>
      </div>
      <div className="educations-body-div">
        <div className="gallery">
          {/* Assuming you have image files in public/nails */}
          <div className="gallery-item">
            <img
              src={`${process.env.PUBLIC_URL}/nails/1.jpg`}
              alt="Image 1"
              className="gallery-image"
            />
          </div>
          <div className="gallery-item">
            <img
              src={`${process.env.PUBLIC_URL}/nails/2.jpg`}
              alt="Image 2"
              className="gallery-image"
            />
          </div>
          <div className="gallery-item">
            <img
              src={`${process.env.PUBLIC_URL}/nails/3.jpg`}
              alt="Image 3"
              className="gallery-image"
            />
          </div>
          <div className="gallery-item">
            <img
              src={`${process.env.PUBLIC_URL}/nails/4.jpg`}
              alt="Image 4"
              className="gallery-image"
            />
          </div>
          <div className="gallery-item">
            <img
              src={`${process.env.PUBLIC_URL}/nails/5.jpg`}
              alt="Image 5"
              className="gallery-image"
            />
          </div>
          <div className="gallery-item">
            <img
              src={`${process.env.PUBLIC_URL}/nails/6.jpg`}
              alt="Image 6"
              className="gallery-image"
            />
          </div>
          <div className="gallery-item">
            <img
              src={`${process.env.PUBLIC_URL}/nails/7.jpg`}
              alt="Image 7"
              className="gallery-image"
            />
          </div>
          <div className="gallery-item">
            <img
              src={`${process.env.PUBLIC_URL}/nails/8.jpg`}
              alt="Image 8"
              className="gallery-image"
            />
          </div>
          {/* Video */}
          {/* <div className="gallery-item">
            <video controls className="gallery-video" width={"100%"}>
              <source
                src={`${process.env.PUBLIC_URL}/nails/video.mp4`}
                type="video/mp4"
              />
            </video>
          </div> */}
        </div>
        <div className="links">
          <h1 className="educations-header" style={{ color: theme.text }}>
            Links
          </h1>
          <a
            {...styles}
            className="general-btn"
            href={
              "https://docs.google.com/document/d/1sj-PgITWauk3rF8LBBgddSZ9rKdzCphnnQZnT6_P3PI/edit?usp=drive_link"
            }
          >
            See my Resume
          </a>
          <a
            {...styles}
            className="general-btn"
            href={
              "https://www.instagram.com/toju_nails"
            }
          >
            Instagram Page
          </a>
          <a
            {...styles}
            className="general-btn"
            href={
              "https://www.etsy.com/shop/CustommadebyToju?ref=seller-platform-mcnav"
            }
          >
            Etsy Shop
          </a>
          <a
            {...styles}
            className="general-btn"
            href={
              "https://tojusnails.com/services"
            }
          >
            Book An Appointment
          </a>
        </div>
      </div>
    </div>
  );
}

export default Educations;
